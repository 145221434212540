import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';

class BrideGroom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inView: false,
    };
  }

  handleIntersection = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting && entry.intersectionRatio >= 0.2) {
      this.setState({ inView: true });
    }
  };

  componentDidMount() {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.2, // When at least 80% of the element is in the viewport
    };

    this.observer = new IntersectionObserver(this.handleIntersection, options);
    this.observer.observe(this.animatedComponent);
  }

  componentWillUnmount() {
    if (this.animatedComponent) {
      this.observer.unobserve(this.animatedComponent);
    }
  }

  render() {
    return (
      <div ref={(node) => (this.animatedComponent = node)} style={{ opacity: this.state.inView ? 1 : 0, transition: 'opacity 2s ease-in-out' }} >
        <Box style={{ height: '2vh' }} />
        <Box style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }} maxWidth='1500px'  >
          <Grid container spacing={2} direction='row' justifyContent='space-evenly' width='80%' alignItems='center'>
            {/* Bride */}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Grid container direction="column" justifyContent="center" alignItems="flex-end">
                <Typography color='white' variant="h3" sx={{ fontFamily: 'Petit Formal Script, cursive', fontWeight:'bold', textAlign: 'right' }}>
                  {this.props.data.bride.name}
                </Typography>
                <Typography color='white' variant="h6" sx={{ fontFamily: 'Petit Formal Script, cursive', fontWeight:'bold', textAlign: 'right' }}>
                  {this.props.data.bride.text}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Avatar alt='Bride' src={this.props.data.bride.image} sx={{ width: 200, height: 200 }} />
              </Grid>
            </Grid>

            {/* Groom */}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Avatar alt='Groom' src={this.props.data.groom.image} sx={{ width: 200, height: 200 }} />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Grid container direction="column" justifyContent="center" alignItems="flex-start">
                <Typography color='white' variant="h3" sx={{ fontFamily: 'Petit Formal Script, cursive', fontWeight:'bold', textAlign: 'left' }}>
                  {this.props.data.groom.name}
                </Typography>
                <Typography color='white' variant="h6" sx={{ fontFamily: 'Petit Formal Script, cursive', fontWeight:'bold', textAlign: 'left' }}>
                  {this.props.data.groom.text}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  }
}

export default BrideGroom;
