import React from 'react';
import { GoogleMap, LoadScriptNext, Marker, InfoWindow } from '@react-google-maps/api';

const containerStyle = {
  width: '200px',
  height: '200px'
};

const googleMapsApiKey = "AIzaSyCtnDkj80cbDtR69WhiAeyVmGjbvvDBqTk"; // Replace with your Google Maps API Key



class Venue extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        center: null,
        markerPositions: [], // Array to store marker positions
        isApiLoaded: true,
        selectedParty: null // Initialize selectedParty to 0 to open the InfoWindow for the first party
      };
    }
  
    handleApiLoaded = () => {
      this.setState({ isApiLoaded: true });
      this.geocodeLocations(this.props.parties);
    };
  
    geocodeLocations(parties) {
      if (!this.state.isApiLoaded) return;
  
      const geocoder = new window.google.maps.Geocoder();
      const markerPositions = [];
  
      parties.forEach((party) => {
        geocoder.geocode({ address: party.location }, (results, status) => {
          if (status === 'OK') {
            markerPositions.push(results[0].geometry.location);
            // If all markers are geocoded, set the state
            if (markerPositions.length === parties.length) {
              this.setState({ markerPositions });
            }
          } else {
            console.error('Geocode was not successful for the following reason: ' + status);
          }
        });
      });
    }
  
    selectParty = (partyIndex) => {
      this.setState({ selectedParty: partyIndex });
    };
  
    closeInfoWindow = () => {
      this.setState({ selectedParty: null });
    };
  
    render() {
      const { parties } = this.props;
      const { selectedParty, markerPositions } = this.state;
  
      return (
        <LoadScriptNext googleMapsApiKey={googleMapsApiKey} onLoad={this.handleApiLoaded}>
          {markerPositions.length > 0 && (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={markerPositions[0]} // Center the map on the first party's location
              zoom={15}
            >
              {parties.map((party, index) => (
                <Marker
                  key={index}
                  position={markerPositions[index]}
                  options={{ icon: { url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png" } }}
                  onClick={() => this.selectParty(index)}
                />
              ))}
  
              {selectedParty !== null && (
                <InfoWindow
                  position={markerPositions[selectedParty]}
                  onCloseClick={this.closeInfoWindow}
                >
                  <div>
                    <h3>{parties[selectedParty].name}</h3>
                    <p>{parties[selectedParty].location}</p>
                    <p>{parties[selectedParty].time}</p>
                    <p>{parties[selectedParty].description}</p>
                  </div>
                </InfoWindow>
              )}
            </GoogleMap>
          )}
        </LoadScriptNext>
      );
    }
  }
  
  export default Venue;

// Maps api Key AIzaSyCtnDkj80cbDtR69WhiAeyVmGjbvvDBqTk