import React, { Component } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Venue from './venue'; // Make sure to import the Venue component
import { Box, Typography } from '@mui/material';

class WeddingTimeline extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobileView: false,
        };
    }

    componentDidMount() {
        // Check if the viewport width is less than 500px (typical mobile breakpoint)
        if (window.innerWidth < 500) {
            this.setState({ isMobileView: true });
        }
        // Add an event listener to handle window resize
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        // Remove the event listener when the component unmounts
        window.removeEventListener('resize', this.handleResize);
    }

    // Handle window resize to determine if it's in mobile view
    handleResize = () => {
        if (window.innerWidth < 500) {
            this.setState({ isMobileView: true });
        } else {
            this.setState({ isMobileView: false });
        }
    };

    renderMobileTimeline() {
        const { church, signing, party } = this.props;

        return (
            <Timeline>
                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot
                            sx={{
                                backgroundColor: 'white',
                                border: '2px solid white',
                                transition: 'background-color 1s ease-in-out, border-color 1s ease-in-out',
                            }}
                        />
                        <TimelineConnector
                            sx={{
                                backgroundColor: 'white',
                                transition: 'background-color 1s ease-in-out',
                            }}
                        />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant='h6' color='white' style={{fontFamily: 'Petit Formal Script, cursive', fontWeight:'bold'}} >Signing</Typography>
                        <Venue parties={signing} isMobileView={this.state.isMobileView} />
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot
                            sx={{
                                backgroundColor: 'white',
                                border: '2px solid white',
                                transition: 'background-color 1s ease-in-out, border-color 1s ease-in-out',
                            }}
                        />
                        <TimelineConnector
                            sx={{
                                backgroundColor: 'white',
                                transition: 'background-color 1s ease-in-out',
                            }}
                        />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant='h6' color='white' style={{fontFamily: 'Petit Formal Script, cursive', fontWeight:'bold'}} >Church</Typography>
                        <Venue parties={church} isMobileView={this.state.isMobileView} />
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot
                            sx={{
                                backgroundColor: 'white',
                                border: '2px solid white',
                                transition: 'background-color 1s ease-in-out, border-color 1s ease-in-out',
                            }}
                        />
                        <TimelineConnector
                            sx={{
                                backgroundColor: 'white',
                                transition: 'background-color 1s ease-in-out',
                            }}
                        />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant='h6' color='white' style={{fontFamily: 'Petit Formal Script, cursive', fontWeight:'bold'}} >Party</Typography>
                        <Venue parties={party} isMobileView={this.state.isMobileView} />
                    </TimelineContent>
                </TimelineItem>
            </Timeline>
        );
    }

    renderNonMobileTimeline() {
        const { church, signing, party } = this.props;

        return (
            <Box width='550px'>
                <Timeline position="alternate">
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot
                                sx={{
                                    backgroundColor: 'white',
                                    border: '2px solid white',
                                    transition: 'background-color 1s ease-in-out, border-color 1s ease-in-out',
                                }}
                            />
                            <TimelineConnector
                                sx={{
                                    backgroundColor: 'white',
                                    transition: 'background-color 1s ease-in-out',
                                }}
                            />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Typography variant='h6' color='white'>Signing</Typography>
                            <Venue parties={signing} />
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot
                                sx={{
                                    backgroundColor: 'white',
                                    border: '2px solid white',
                                    transition: 'background-color 1s ease-in-out, border-color 1s ease-in-out',
                                }}
                            />
                            <TimelineConnector
                                sx={{
                                    backgroundColor: 'white',
                                    transition: 'background-color 1s ease-in-out',
                                }}
                            />
                        </TimelineSeparator>
                        <TimelineContent style={{ alignItems: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                            <Typography variant='h6' color='white'>Church</Typography>
                            <Venue parties={church} />
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot
                                sx={{
                                    backgroundColor: 'white',
                                    border: '2px solid white',
                                    transition: 'background-color 1s ease-in-out, border-color 1s ease-in-out',
                                }}
                            />
                            <TimelineConnector
                                sx={{
                                    backgroundColor: 'white',
                                    transition: 'background-color 1s ease-in-out',
                                }}
                            />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Typography variant='h6' color='white'>Party</Typography>
                            <Venue parties={party} />
                        </TimelineContent>
                    </TimelineItem>
                </Timeline>
            </Box>
        );
    }

    render() {
        return (
            <Box style={{ overflowX: 'hidden' }}>
                {this.state.isMobileView ? this.renderMobileTimeline() : this.renderNonMobileTimeline()}
            </Box>
        );
    }
}

export default WeddingTimeline;
