import React, { Component } from 'react';
import axios from 'axios';
import {
    Typography,
    TextField,
    Button,
    Grid,
    Paper,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@mui/material';

const formStyles = {
    background: 'transparent',
    padding: '20px',
    maxWidth: '400px',
    margin: '0 auto',
    border: '2px solid white',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    fontFamily: 'Petit Formal Script, cursive',
    fontWeight:'bold'
};

const textfieldStyle = {
    color: 'white',
    background: 'transparent',
    border: '2px solid white',
    borderRadius: '10px',
    fontFamily: 'Petit Formal Script, cursive',
    fontWeight:'bold'
};

const inputtextfieldStyle = {
    color: 'white',
    fontFamily: 'Petit Formal Script, cursive',
    fontWeight:'bold'
};

const fieldStyles = {
    color: 'white',
    fontFamily: 'Petit Formal Script, cursive',
    fontWeight:'bold'
};

const menuStyle = {
    fontFamily: 'Petit Formal Script, cursive',
    fontWeight:'bold'

};

const selectStyle = {
    color: 'white', // Text color
    background: 'transparent', // Transparent background
    border: '2px solid white', // White border
    borderRadius: '10px', // Rounded corners
    width: '100%', // Full width
    fontFamily: 'Petit Formal Script, cursive',
    fontWeight:'bold'
};

class RSVPForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            message: '',
            emailRsvp: '',
            email: '',
            attendees: 1, // Default to 'Single' attendee
            successMessage: '',
            errorMessage: '',
        };
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleAttendeesChange = (event) => {
        this.setState({ attendees: event.target.value });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        // Prepare data to send to the API
        const { name, message, attendees, emailRsvp } = this.state;
        const formData = { name, email: 'test@robotics-media.com', message, attendees, emailRsvp, ...this.props.data };

        // Replace with your API endpoint
        const apiEndpoint = 'https://api-wed.robotics-media.com/rsvp';

        axios
            .post(apiEndpoint, formData)
            .then((response) => {
                // Handle successful response
                this.setState({
                    successMessage: 'RSVP submitted successfully!',
                    errorMessage: '',
                    name: '',
                    emailRsvp: '',
                    message: '',
                    email: '',
                    attendees: 1, // Reset to 'Single' attendee
                });
            })
            .catch((error) => {
                // Handle error response
                this.setState({
                    successMessage: '',
                    errorMessage: 'An error occurred. Please try again later.',
                });
            });
    };

    render() {
        const { name, emailRsvp, message, attendees, successMessage, errorMessage } = this.state;

        return (
            <Paper elevation={4} style={formStyles}>
                <Typography style={{fontFamily: 'Petit Formal Script, cursive', fontWeight:'bold' }} variant="h4" gutterBottom color="white">
                    Wedding RSVP
                </Typography>
                <Typography style={{fontFamily: 'Petit Formal Script, cursive', fontWeight:'bold' }} variant="h6" gutterBottom color="white">
                Please RSVP by May 15, 2024
                </Typography>
                {successMessage && (
                    <Typography style={{fontFamily: 'Petit Formal Script, cursive', fontWeight:'bold', color:'white' }} variant="body1" color="success"  >
                        {successMessage}
                    </Typography>
                )}
                {errorMessage && (
                    <Typography variant="body1" color="error"  style={{ fontFamily: 'Petit Formal Script, cursive', fontWeight:'bold', color:'red' }} >
                        {errorMessage}
                    </Typography>
                )}
                <form onSubmit={this.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Name"
                                fullWidth
                                name="name"
                                value={name}
                                onChange={this.handleChange}
                                required
                                InputProps={{ style: inputtextfieldStyle }}
                                InputLabelProps={{ style: fieldStyles }}
                                style={textfieldStyle}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Email"
                                fullWidth
                                name="emailRsvp"
                                type='email'
                                value={emailRsvp} // Change this line to bind to emailRsvp
                                onChange={this.handleChange}
                                required
                                InputProps={{ style: inputtextfieldStyle }}
                                InputLabelProps={{ style: fieldStyles }}
                                style={textfieldStyle}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Mentions"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                name="message"
                                value={message}
                                onChange={this.handleChange}
                                required
                                InputProps={{ style: inputtextfieldStyle }}
                                InputLabelProps={{ style: fieldStyles }}
                                style={textfieldStyle}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel style={fieldStyles}>
                                    Number of Attendees
                                </InputLabel>
                                <Select
                                    value={attendees}
                                    onChange={this.handleAttendeesChange}
                                    style={selectStyle} // Apply the selectStyle to the Select component
                                >
                                    <MenuItem value={1} style={menuStyle} >Single</MenuItem>
                                    <MenuItem value={2} style={menuStyle} >Couple</MenuItem>
                                    <MenuItem value={3} style={menuStyle} >Family of 3</MenuItem>
                                    <MenuItem value={4} style={menuStyle} >Family of 4</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        style={{
                            marginTop: '10px',
                            color: 'black',
                            background: 'white',
                            border: '2px solid white',
                            borderRadius: '10px',
                        }}
                    >
                        Submit RSVP
                    </Button>
                </form>
            </Paper>
        );
    }
}

export default RSVPForm;
