import React, { Component, forwardRef } from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

const TimerContainer = styled('div')(({ visible }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0, // Initially set to 0 opacity
    transition: ' opacity 1s ease-in-out', // Add a transition effect for the opacity
}));

const CircularTimer = ({ value, label }) => (
    <div style={{ position: 'relative', width: '200px', height: '200px' }}>
        <CircularProgress
            variant="determinate"
            value={value}
            size={150}
            thickness={4}
            style={{ color: 'white', backgroundColor: 'rgba(255, 255, 255, 0.1)', borderRadius: '50%'}}
        />
        <Typography
            variant="h6"
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: 'white',
                fontFamily: 'Petit Formal Script, cursive',
                fontWeight:'bold'
            }}
        >
            {label}
        </Typography>
    </div>
);

class CountdownTimer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            targetDate: new Date(props.targetDate).getTime(),
            timeRemaining: this.calculateTimeRemaining(props.targetDate),
            isVisible: false,
        };
    }

    handleIntersection = (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting && entry.intersectionRatio >= 0.1) {
            this.setState({ isVisible: true });
        }
    };

    componentDidMount() {
        this.interval = setInterval(this.updateTimer, 1000);

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5, // When at least 50% of the element is in the viewport
        };

        this.observer = new IntersectionObserver(this.handleIntersection, options);

        if (this.timerRef) {
            this.observer.observe(this.timerRef);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        if (this.timerRef.current) {
            this.observer.unobserve(this.timerRef.current);
        }
    }

    calculateTimeRemaining = (targetDate) => {
        const now = new Date().getTime();
        const timeRemaining = Math.max(targetDate - now, 0);
        return timeRemaining;
    };

    updateTimer = () => {
        const { targetDate } = this.state;
        const timeRemaining = this.calculateTimeRemaining(targetDate);

        if (timeRemaining === 0) {
            clearInterval(this.interval);
        }

        this.setState({ timeRemaining });
    };

    formatTime = (milliseconds) => {
        const seconds = Math.floor((milliseconds / 1000) % 60);
        const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
        const hours = Math.floor((milliseconds / 1000 / 3600) % 24);
        const days = Math.floor(milliseconds / 1000 / 3600 / 24);

        return {
            days,
            hours,
            minutes,
            seconds,
        };
    };

    render() {
        const { timeRemaining, isVisible } = this.state;
        const { days, hours, minutes, seconds } = this.formatTime(timeRemaining);

        return (
            <TimerContainer
                style={{ opacity: isVisible ? 1 : 0 }}
                ref={(ref) => (this.timerRef = ref)}
            >
                    <Grid
                        xs={2} sm={2} md={2}
                        container
                        display='flex'
                        justifyContent="space-evenly"
                        alignItems="center"
                        spacing={4}
                        style={{
                            minWidth:'200px',
                            maxWidth: '1000px',
                            maxHeight: '100%',
                            paddingTop: '40px',
                        }}
                    >
                        <Grid item>
                            <CircularTimer value={days} label={`${days} days`} />
                        </Grid>
                        <Grid item>
                            <CircularTimer value={hours} label={`${hours} hours`} />
                        </Grid>
                        <Grid item>
                            <CircularTimer value={minutes} label={`${minutes} minutes`} />
                        </Grid>
                        <Grid item>
                            <CircularTimer value={seconds} label={`${seconds} seconds`} />
                        </Grid>
                    </Grid>
            </TimerContainer>
        );
    }
}

const CountdownTimerWithRef = forwardRef((props, ref) => (
    <CountdownTimer {...props} timerRef={ref} />
));

export default CountdownTimerWithRef;
