import React, { Component } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Container from '@mui/material/Container';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import WoodishBackground from './assets/Asset1.svg'; // Import the SVG file

import CountdownTimerWithRef from './countDown';
import WeddingTimeline from './timeline';
import BrideGroom from './brideandgroom';
import RSVPForm from './rsvp';
import Footer from './footer';

// const drawerWidth = 240;

const MainWrapper = styled('main')({
  flexGrow: 1,
  textAlign: 'center',
});

const AppBar = styled(MuiAppBar)({
  width: '100%',
  backgroundImage: `url(${WoodishBackground})`, // Set background image
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  opacity: '0.9',
});

const DrawerHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '8px 12px',
});

const AppBarContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between', // Align content to the right
  width: '100%',
});

const WeddingText = styled(Typography)({
  border: '2px solid #402F1D', // Woodish color
  padding: '8px',
  borderRadius: '4px', // Rounded corners
  backgroundColor: '#402F1D',
  color: 'white', // Text color
  fontFamily: 'Petit Formal Script, cursive'
});

const IconButtonWithBorder = styled(IconButton)({
  border: '2px solid #402F1D', // Woodish color
  borderRadius: '50%', // Makes the IconButton round
  padding: '4px', // Adjust padding as needed
  background: '#402F1D',
});


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      this.handleDrawerClose(); // Close the drawer after clicking an item
    }
  };

  render() {
    const { open } = this.state;

    const data = {
      date: "June 20, 2024",
      website:"example.com",
      image: "https://images.unsplash.com/photo-1501901609772-df0848060b33?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      bride: {
        name: "Jane",
        email:"bride@example.com",
        phone:"+44 07544 543 343",
        text: "As the stars align, two souls become one. Join us in celebrating the love that brought us together, and the adventure that lies ahead",
        image: "https://images.unsplash.com/photo-1580489944761-15a19d654956?q=80&w=1961&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      groom: {
        name: "John",
        email:"groom@example.com",
        phone:"+44 07544 543 343",
        text: "Life has brought me the greatest gift – the love of my life. With joy in my heart, I invite you to share in the beginning of our beautiful journey together.",
        image: "https://images.unsplash.com/photo-1522556189639-b150ed9c4330?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      party: [
        {
          location: "Complex Stejarul, Padurea Garboavele, Tulucești 800205, Romania",
          time: "9:00 PM",
          description: "Join us for the ceremony at the Grand Ballroom.",
          name: "Hangar",
        }
      ],
      church: [
        {
          location: "Fortified Church Precista,Strada Traian nr. 1, Galați 800043, Romania",
          time: "12:00 AM",
          description: "Join us for the ceremony at the Fortified Church Precista.",
          name: "Biserica Precista",
        }
      ],
      signing: [
        {
          location: "Office of Civil Status,Strada Traian 93, Galați, Romania",
          time: "14:00 PM",
          description: "Join us for the signing of papers.",
          name: "Starea Civila",
        }
      ],
      events: [
        {
          title: "Ceremony",
          time: "4:00 PM",
          description: "Join us for the ceremony at the Grand Ballroom.",
        },
        {
          title: "Reception",
          time: "6:00 PM",
          description: "Dinner and dancing to follow.",
        },
      ],
      story: [
        { year: "2012", text: "Met in college" },
        { year: "2018", text: "Moved to New York" },
        { year: "2023", text: "Got engaged!" },
      ],
      gallery: [
        // URLs to images
      ],
      rsvp: "Please RSVP by May 15, 2024",
      
    };

    return (
      <Box className='body' sx={{  display: 'flex', height: '100%' }}>
        <CssBaseline />
        <AppBar position="fixed">
          <Toolbar>
            <AppBarContent>
              <WeddingText variant="h6" noWrap button onClick={() => this.scrollToSection('invitation')}>
                {data.bride.name} & {data.groom.name}'s Wedding
              </WeddingText>
              <IconButtonWithBorder
                aria-label="open drawer"
                onClick={this.handleDrawerOpen}
                edge="end"
                color="inherit"
              >
                <MenuIcon />
              </IconButtonWithBorder>
            </AppBarContent>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="temporary"
          anchor="right"
          open={open}
          onClose={this.handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Container sx={{ height: '100%', background: `url(${WoodishBackground})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', color: 'white' }}>
            <DrawerHeader sx={{ background: 'transparent' }}>
              <IconButton onClick={this.handleDrawerClose}>
                <ChevronLeftIcon sx={{ color: 'white' }} />
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List sx={{ textAlign: "center", width: "200px", padding: "40px" }} >
              <ListItem button onClick={() => this.scrollToSection('invitation')}>
                <ListItemText primary="Invitation" />
              </ListItem>
              <ListItem button onClick={() => this.scrollToSection('us')}>
                <ListItemText primary="Us" />
              </ListItem>
              <ListItem button onClick={() => this.scrollToSection('date')}>
                <ListItemText primary="Date" />
              </ListItem>
              <ListItem button onClick={() => this.scrollToSection('venue')}>
                <ListItemText primary="The Day" />
              </ListItem>
              <ListItem button onClick={() => this.scrollToSection('rsvp')}>
                <ListItemText primary="RSVP" />
              </ListItem>
            </List>
          </Container>
        </Drawer>
        <MainWrapper style={{backgroundColor:'rgba(0, 0, 0, 0.6)'}}>
          <DrawerHeader />
          <Container id='invitation' style={{
            backgroundImage: `url(${data.image})`,
            height: '100vh',
            maxWidth: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundPositionX: '60%',
            scale: '100%',
          }}>
            <Box
              style={{
                alignItems: 'center',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                paddingBottom: '15vh',
                position: 'relative',
                zIndex: 1,
              }}
            >
              <Typography color='white' variant="h3" style={{ fontFamily: 'Petit Formal Script, cursive', padding: '10px', animation: 'fadeInText 2s ease-in-out', }} > Wedding Invitation </Typography>
              <Typography color='white' variant="h6" style={{ fontFamily: 'Petit Formal Script, cursive', maxWidth:'100%', padding: '10px', animation: 'fadeInText 3s ease-in-out', }} > We are excited to invite you to our wedding, {data.bride.name} & {data.groom.name}. </Typography>
              <Typography color='white' variant="h6" style={{ fontFamily: 'Petit Formal Script, cursive', animation: 'fadeInText 4s ease-in-out' }} > Save the Date: {data.date} </Typography>
            </Box>
          </Container>
          <Container id='us' style={{ backgroundColor: 'transparent', alignItems: 'center', flexDirection: 'column', display: 'flex' }} >
            <Box style={{ height: '80px' }} />
            <Typography color='white' variant="h4" style={{ fontFamily: 'Petit Formal Script, cursive', fontWeight:'bold', paddingTop: '40px' }}>
              The Bride & Groom
            </Typography>
            <BrideGroom data={data} />
          </Container>
          <Container id='date' style={{ backgroundColor: ' transparent', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }} >
            <Box style={{ height: '80px' }} />
            <Typography style={{ fontFamily: 'Petit Formal Script, cursive', fontWeight:'bold', color: 'white' }} variant="h4" maxWidth='60%' >
              Time Remaining to our Wedding:
            </Typography>
            <CountdownTimerWithRef targetDate={data.date} />
          </Container>
          <Container id='venue' style={{ backgroundColor: 'transparent', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
            <Box style={{ height: '100px' }} />
            <Typography color='white' variant="h4" style={{fontFamily: 'Petit Formal Script, cursive', fontWeight:'bold',}}  >Venue</Typography>
            <WeddingTimeline church={data.church} signing={data.signing} party={data.party} />
          </Container>
          <Container id='rsvp' style={{ backgroundColor: 'transparent' }}>
            <Box style={{ height: '80px' }} />
            <Typography color='white' variant="h4" style={{fontFamily: 'Petit Formal Script, cursive', fontWeight:'bold',}} >Let us know</Typography>
            <Box style={{ height: '40px' }} />
            <RSVPForm data={data} />
            <Box style={{ height: '80px' }} />
          </Container>
          <Footer/>
        </MainWrapper>
      </Box >
    );
  }
}

export default App;
